import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `45%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({ modalIsOpen, setModalIsOpen, data }) {
  const handleClose = () => setModalIsOpen(false);
  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Dados do comprador
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>Nome do comprador: {data?.name}</S.Text>
          </S.ContainerTitle>
          <S.DivRow>
            <S.Text>{data.document_type}: {data?.document}</S.Text>
            <S.Text>Telefone: {data?.phone}</S.Text>
            <S.Text>E-mail: {data?.email}</S.Text>
          </S.DivRow>
          <S.DivRow>
            <S.Text>Cidade: {data?.address?.city}</S.Text>
            <S.Text>Endereco: {data?.address?.street}</S.Text>
            <S.Text>Numero: {data?.address?.street_number}</S.Text>
          </S.DivRow>
          <S.DivRow>
            <S.Text>Cartão: **** **** **** 1231</S.Text>
            <S.Text>Bandeira: Mastercard</S.Text>
            <S.Text>Data de expiração: 03/2030</S.Text>
          </S.DivRow>
          <S.Text>Total gasto: R$ {data?.total_buy}</S.Text>

          {/* <S.DivRow>
            <S.Text>Cartão: **** **** **** 1231</S.Text>
            <S.Text>Bandeira: Mastercard</S.Text>
            <S.Text>Data de expiração: 03/2030</S.Text>
          </S.DivRow> */}
        </Typography>
      </Box>
    </Modal>
  );
}
