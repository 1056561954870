import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";
import * as React from "react";
import FileUpload from "react-material-file-upload";
import { useNavigate, useLocation } from "react-router-dom";
import MainLaylout from "../../layouts/MainLayout";
import Input from "../../components/Input";
import Label from "../../components/Label";
import * as S from "./style";
import { TextField } from "@mui/material";
import api from "../../services/index";
import { getBase64 } from "../../utils/getBase64";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GlobalContext } from "../../context/GlobalContext";
import { cnpjMask, phoneMask, dateMask, cpfMask } from "../../utils/masks";

const Tab = styled(TabUnstyled)`
  color: #0092d4;
  font-size: 1rem;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    background-color: #f4f4f4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)(() => `margin-bottom: 41px;`);

const initialFormStateOneStep = {
  situation: '',
  financial_situation: '',
  name: '',
  phone: '',
  email: '',
  birthday: '',
  genre: '',
  marital_status: '',
  rg: '',
  nationality: '',
  profession: '',
  mother_name: '',
  father_name: '',
  client_type: "PF",
  observation: ''
};

const initialAddress = {
  zip_code: '',
  state: '',
  city: '',
  street: '',
  number: '',
  neighborhood: '',
  complement: '',
}

const initialFormStateBankInfo = {
  customer_id: "",
  bank_id: '',
  agency: "",
  account: "",
  cpf: "",
  owner_account: "",
};

const disabledTab = {
  color: '#c3c2c2',
  cursor: 'unset'
}

function RegisterShopPhysical() {
  const { customerId, setCustomerId } = React.useContext(GlobalContext)
  const { state, pathname } = useLocation()

  const MySwal = withReactContent(Swal)

  const [fileCNHFront, setFileCNHFront] = React.useState([]);
  const [fileCNHBack, setFileCNHBack] = React.useState([]);
  const [fileEndereco, setFileEndereco] = React.useState([]);
  const [fileMared, setFileMared] = React.useState([]);
  const [fileSelfie, setFileSelfie] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState(initialFormStateOneStep);
  const [adress, setAdress] = React.useState(initialAddress);

  const [formBankInfo, setFormBankInfo] = React.useState(
    initialFormStateBankInfo
  );

  const requestType = state ? 'update' : 'register'

  React.useEffect(() => {
    if (state) {
      getCustomerInfo()
      setCustomerId(state.customer_id)
    }
  }, [])

  
  function setInput(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setForm((form) => ({ ...form, ...newValue }));
    };
  }

  function setInputBankInfo(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setFormBankInfo((formBankInfo) => ({
        ...formBankInfo,
        ...newValue,
      }));
    };
  }

  async function getCustomerInfo() {
    const formType = pathname.split('-')[2]

    try {
      setLoading(true);
      const res = await api.post("/v1/gateway/admin/customers/list/detail", {
        customer_id: state.customer_id,
      });

      const data = formType === 'pj'
        ? res.data?.modelo_detalhe_pj?.customer
        : res.data?.modelo_detalhe_pf?.customer

      setForm({
        situation: data.situation,
        financial_situation: data.financial_situation,
        name: data.name,
        company_name: data.company_name,
        email: data.email,
        cnpj: data.cnpj,
        client_type: data.client_type,
        phone: data.phone,
        whatsapp: data.whatsapp,
      });
      setAdress({
        id: data.address.id,
        zip_code: data.address.zip_code,
        state: data.address.state,
        city: data.address.city,
        street: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        complement: data.address.complement,
      });
      setFormBankInfo({
        id: data.bank.id,
        bank_id: data.bank.bank_id,
        agency: data.bank.agency,
        account: data.bank.account,
        owner_account: data.bank.owner_account,
        owner_document: data.bank.owner_document,
      });
      // setFileCNHFront(data.documents.cnh_frente)
      // setFileCNHBack(data.documents.cnh_verso)
      // setFileEndereco(data.documents.comp_endereco)
      // setFileMared(data.documents.cert_casamento)
      // setFileSelfie(data.documents.selfie)
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  
  // pegar os documentos como base64 e tranformar em file

  async function registerCustomers() {
    const body = {
      situation: form.situation,
      financial_situation: form.financial_situation,
      name: form.name,
      phone: form.phone,
      email: form.email,
      birthday: form.birthday,
      genre: form.genre,
      marital_status: form.marital_status,
      rg: form.rg,
      nationality: form.nationality,
      profession: form.profession,
      mother_name: form.mother_name,
      father_name: form.father_name,
      client_type: "PF",
      observation: form.observation || undefined,
      address: {
        zip_code: adress.zip_code,
        state: adress.state,
        city: adress.city,
        street: adress.street,
        number: adress.number,
        neighborhood: adress.neighborhood,
        complement: adress.complement,
      },
    };

    const hasEmptyBody = Object.values(body).some(item => item === '')
    const hasEmptyBodyAddress = Object.values(body.address).some(item => item === undefined)

    if(hasEmptyBody || hasEmptyBodyAddress) {
      MySwal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        text: 'Preencha todos os campos!',
        customClass: {
          confirmButton: 'btn btn-error'
        }
      })
      return
    }

    try {
      setLoading(true);
      const res = requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/update`, body)
        : await api.post(`v1/gateway/admin/customers/register`, body)
      setCustomerId(res.data.customer_id);
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados do cliente cadastrado!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerBank() {
    const hasEmptyBankInfo = Object.values(formBankInfo).some(item => item === '')

    if (hasEmptyBankInfo) {
      MySwal.fire({
        icon: 'error',
        title: 'algo deu errado',
        text: 'Preencha todos os campos!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      return
    }

    const body = { ...formBankInfo, customer_id: customerId}

    try {
      setLoading(true);
      requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/bank/update`, body)
        : await api.post(`v1/gateway/admin/customers/bank/register`, body)

      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados bancarios cadastrados!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerDocument() {
    try {
      setLoading(true);
      let cnh_frente = await getBase64(fileCNHFront[0]);
      let cnh_verso = await getBase64(fileCNHBack[0]);
      let selfie = await getBase64(fileSelfie[0]);
      let comp_endereco = await getBase64(fileEndereco[0]);
      let cert_casamento = await getBase64(fileMared[0]);
      const body = {
        customer_id: customerId,
        cnh_frente: cnh_frente,
        cnh_verso: cnh_verso,
        selfie: selfie,
        comp_endereco: comp_endereco,
        cert_casamento: cert_casamento,
      };

      requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/document/update`, body)
        : await api.post(`v1/gateway/admin/customers/document/register`, body)
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const navigate = useNavigate();

  return (
    <MainLaylout title={"Cadastro de Lojistas"}>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Dados do cliente</Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Dados bancários
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Documentos enviados
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <S.Title>Dados do Cliente</S.Title>

          <S.Form>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="SituacaoCadastral">Situação Cadastral</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, situation: e.target.value })
                  }
                  value={form.situation}
                >
                  <option value="" selected>...</option> 
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </S.Select>
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="SituacaoFinanceira">Situação Financeira</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, financial_situation: e.target.value })
                  }
                  value={form.financial_situation}
                >
                  <option value="" selected>...</option> 
                  <option value="habilitado">Habilitado</option>
                  <option value="bloqueado">Bloqueado</option>
                </S.Select>
              </S.divWrapper>
            </S.Column>
            <S.fullInput>
              <Label htmlFor="NomeCompleto">Nome completo</Label>
              <Input
                name="name"
                onChange={setInput("name")}
                value={form.name}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Telefone">Telefone</Label>
                <Input
                  name="phone"
                  onChange={setInput("phone")}
                  value={phoneMask(form.phone)}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="E-mail">E-mail</Label>
              <Input
                name="email"
                onChange={setInput("email")}
                value={form.email}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="DataNascimento">Data Nascimento</Label>
                <Input
                  name="birthday"
                  onChange={setInput("birthday")}
                  value={dateMask(form.birthday)}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Sexo">Genero</Label>
                <S.Select
                  onChange={(e) => setForm({ ...form, genre: e.target.value })}
                  value={form.genre}
                >
                  <option value="" selected>...</option> 
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </S.Select>
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Sexo">Estado Civil</Label>
                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, marital_status: e.target.value })
                  }
                  value={form.marital_status}
                >
                  <option value="" selected>...</option> 
                  <option value="solteiro">Solteiro(a)</option>
                  <option value="casado">Casado(a)</option>
                  <option value="viuvo">Viúvo(a)</option>
                  <option value="divorciado">Divorciado(a)</option>
                </S.Select>
              </S.divWrapper>
            </S.Column>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="RG">RG</Label>
                <Input name="rg" onChange={setInput("rg")} value={form.rg} />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Nacionalidade">Nacionalidade</Label>
                <Input
                  name="nationality"
                  onChange={setInput("nationality")}
                  value={form.nationality}
                />
              </S.divWrapper>
            </S.Column>
            <S.fullInput>
              <Label htmlFor="Profissao">Profissão</Label>
              <Input
                name="profession"
                onChange={setInput("profession")}
                value={form.profession}
              />
            </S.fullInput>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="CEP">CEP</Label>
                <Input
                  name="zip_code"
                  onChange={(e) =>
                    setAdress({ ...adress, zip_code: e.target.value })
                  }
                  value={adress.zip_code}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Cidade">Cidade</Label>
                <Input
                  name="city"
                  onChange={(e) =>
                    setAdress({ ...adress, city: e.target.value })
                  }
                  value={adress.city}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="UF">Estado</Label>
                <Input
                  name="state"
                  onChange={(e) =>
                    setAdress({ ...adress, state: e.target.value })
                  }
                  value={adress.state}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Endereco">Endereço</Label>
              <Input
                name="street"
                onChange={(e) =>
                  setAdress({ ...adress, street: e.target.value })
                }
                value={adress.street}
              />
            </S.fullInput>

            <S.divWrapper>
              <Label htmlFor="Numero">Número</Label>
              <Input
                name="number"
                onChange={(e) =>
                  setAdress({ ...adress, number: e.target.value })
                }
                value={adress.number}
                type="number"
              />
            </S.divWrapper>

            <S.fullInput>
              <Label htmlFor="Bairro">Bairro</Label>
              <Input
                name="neighborhood"
                onChange={(e) =>
                  setAdress({ ...adress, neighborhood: e.target.value })
                }
                value={adress.neighborhood}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Complemento">Complemento</Label>
              <Input
                name="complement"
                onChange={(e) =>
                  setAdress({ ...adress, complement: e.target.value })
                }
                value={adress.complement}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="NomePai">Nome do Pai</Label>
              <Input
                name="father_name"
                onChange={setInput("father_name")}
                value={form.father_name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="NomeMae">Nome da Mãe</Label>
              <Input
                name="mother_name"
                onChange={setInput("mother_name")}
                value={form.mother_name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Obs">Observação</Label>
              <TextField 
                fullWidth 
                multiline
                rows={4}
                maxRows={8}
                onChange={setInput("observation")}
                value={form.observation}
              />
            </S.fullInput>
            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerCustomers();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  navigate("/listagem-lojista");
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={1}>
          <S.Title>Dados Bancários</S.Title>

          <S.Form>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Banco</Label>
              <S.Select
                onChange={(e) =>
                  setFormBankInfo({ ...formBankInfo, bank_id: e.target.value })
                }
                value={formBankInfo.bank_id}
              >
                <option value="" selected>...</option>
                <option value={1}>Bradesco</option>
                <option value={2}>Inter</option>
                <option value={3}>C6</option>
                <option value={4}>Nubank</option>
                <option value={5}>Itau</option>
                <option value={6}>CAIXA</option>
                <option value={7}>Sicredi</option>
              </S.Select>
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Agência">Agência</Label>
                <Input
                  name="agency"
                  onChange={setInputBankInfo("agency")}
                  value={formBankInfo.agency}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Conta Corrente">Conta Corrente</Label>
                <Input
                  name="account"
                  onChange={setInputBankInfo("account")}
                  value={formBankInfo.account}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="CPF">CPF</Label>
                <Input
                  name="cpf"
                  onChange={setInputBankInfo("cpf")}
                  value={cpfMask(formBankInfo.cpf)}
                  maxLength={12}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Nome do titular">Nome do titular</Label>
              <Input
                name="owner_account"
                onChange={setInputBankInfo("owner_account")}
                value={formBankInfo.owner_account}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerBank();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  navigate("/listagem-lojista");
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={2}>
          <S.uploadWrapper>
            <Label htmlFor="CNH">CNH - Frente</Label>
            <FileUpload value={fileCNHFront} onChange={setFileCNHFront} />
          </S.uploadWrapper>
          <S.uploadWrapper>
            <Label htmlFor="CNH">CNH - Verso</Label>
            <FileUpload value={fileCNHBack} onChange={setFileCNHBack} />
          </S.uploadWrapper>
          <S.uploadWrapper>
            <Label htmlFor="CNH">Foto - Selfie</Label>
            <FileUpload value={fileSelfie} onChange={setFileSelfie} />
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="Comprovante Endereço">Comprovante Endereço</Label>
            <FileUpload value={fileEndereco} onChange={setFileEndereco} />
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="Certidão de Casamento">Certidão de Casamento</Label>
            <FileUpload value={fileMared} onChange={setFileMared} />
          </S.uploadWrapper>

          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerDocument();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                navigate("/listagem-lojista");
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
      </TabsUnstyled>
    </MainLaylout>
  );
}

export default RegisterShopPhysical;
