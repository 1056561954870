import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(241px, 1fr) 11fr;
  height: 100vh;
`;

export const Navbar = styled.div`
  background-color: #0092D4;
`;

export const Header = styled.header`
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 55px;
  cursor: pointer;

  p {
    margin-top: 7px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }
`;

export const Items = styled.div`
  display: flex;
  justify-content: center;
  p {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 300;
    color: #fff;
  }
`;
export const MenuItems = styled.div`
  margin: 20px 0px 0px 10px;  
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  p {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 300;
    color: #fff;
  }
`;

export const GroupItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Content = styled.div`
  padding: 26px 44px 0 49px;
`;

export const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Title = styled.p`
  font-size: 50px;
  font-weight: 400;
  color: #000;
  margin-top: 25px;
`;

export const Button = styled.button`
  width: 97px;
  height: 25px;
  border-radius: 10px;
  color: #fff;
  background-color: #000;
`;
