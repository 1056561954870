import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(241px, 1fr) 11fr;
  height: 100vh;
`;

export const Section = styled.div`
  margin: 20px;
`;

export const Navbar = styled.div`
  background-color: #07254f;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 55px;

  p {
    margin-top: 7px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }
`;

export const Items = styled.div`
  p {
    margin: 7px 0px 10px 0px;
    font-size: 15px;
    font-weight: bold;
    color: #000;
  }
`;

export const Content = styled.div`
  padding: 10px 44px 0 49px;
`;

export const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Title = styled.p`
  font-size: 40px;
  font-weight: 400;
  color: #000;
  margin-top: 25px;
`;

export const Button = styled.button`
  width: 97px;
  height: 25px;
  border-radius: 10px;
  color: #fff;
  background-color: #000;
`;

export const Pipe = styled.div`
  height: 90%;
  margin: 10px;
  border-right: 2px solid #000;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
