import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ListBuyer from "./pages/ListBuyer";
import ListFees from "./pages/ListFees";
import ListShop from "./pages/ListShop";
import HistoryTransactions from "./pages/HistoryTransactions";
import RegisterShopPhysical from "./pages/RegisterShopPhysical";
import RegisterShopJudicial from "./pages/RegisterShopJudicial";
import FinancialStatement from "./pages/FinancialStatement";
import ExecutionPayments from "./pages/ExecutionPayments";
import WithdrawalRequest from "./pages/WithdrawalRequest";

export default function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/listagem-lojista" element={<ListShop />} />
      <Route exact path="/cadastro-lojista-pf" element={<RegisterShopPhysical />} />
      <Route exact path="/cadastro-lojista-pj" element={<RegisterShopJudicial />} />
      <Route exact path="/cadastro-comprador" element={<ListBuyer />} />
      <Route exact path="/historico-de-transacoes" element={<HistoryTransactions />}/>
      <Route exact path="/listagem-de-taxas" element={<ListFees />} />
      <Route exact path="/extrato-financeiro" element={<FinancialStatement />} />
      <Route exact path="/execucao-pagamentos" element={<ExecutionPayments />} />
      <Route exact path="/solicitacao-saque" element={<WithdrawalRequest />} />
    </Routes>
  );
}
