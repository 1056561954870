import styled from "styled-components";

export const Header = styled.header`
  margin: 27px 0 10px 0;
  display: flex;
  justify-content: flex-end;
`;

export const Select = styled.select`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 20px;
`;
export const Input = styled.input`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;

  :focus {
    outline: none;
  }
`;

export const ButtonCloud = styled.button`
  background-color: #01b797;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
`;

export const ImgCloud = styled.img`
  padding-right: 5px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`
