import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `50%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalCheck({ modalIsOpenCheck, setModalIsOpenCheck , UpdateFinancialStatement}) {
  const handleClose = () => setModalIsOpenCheck(false);
  return (
    <Modal
      open={modalIsOpenCheck}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>
              A atualização do sistema com essas operações devem ser feitas com
              extrema atenção para que não haja conflitos, fraudes ou
              divergências financeiras.
            </S.Text>
            <S.BottomInfo>
            <S.TextSecondary>Você tem certeza que podemos atualizar o sistema?</S.TextSecondary>
            <S.BottomButtons>
              <S.OutlineButton
             onClick={UpdateFinancialStatement}
              >
                <p>SIM</p>
              </S.OutlineButton>
              <S.DefaultButton
              onClick={handleClose}
              >
                <p>NÃO</p>
              </S.DefaultButton>
            </S.BottomButtons>
          </S.BottomInfo>
          </S.ContainerTitle>
          <S.DivRow></S.DivRow>
        </Typography>
      </Box>
    </Modal>
  );
}
