import { BrowserRouter } from "react-router-dom/dist";
import Router from "./routes";
import GlobalProvider from "./context/GlobalContext";

function App() {
  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
}

export default App;
