import styled from "styled-components";

export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

export const BigTitle = styled.p`
  font-size: 30px;
  font-weight: 600;
  margin: 30px 0px 15px 0px;
`;

export const Form = styled.form`
  width: 738px;
  margin-top: 20px;
`;

export const Input = styled.input`
  margin-bottom: 17px;
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 4px;

  :focus-visible {
    outline: 1px solid #d9d9d9;
  }
`;

export const divWrapper = styled.div`
  display: block;
  width: 33%;
  padding-right: 10px;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  /* gap: 10px; */
`;

export const fullInput = styled.div`
  padding-right: 15px;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 17px;
`;

export const Label = styled.label`
  display: block;
  font-size: 15px;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 17px;
  margin: 15px;
`;

export const Button = styled.button`
  display: block;
  width: 159px;
  height: 40px;
  margin-top: 2px;
  background-color: #01B797;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;

export const uploadWrapper = styled.div`
  margin-top: 20px;
`;

export const OutlineButton = styled.button`
  display: block;
  width: 159px;
  height: 40px;
  margin-top: 2px;
  background-color: #fff;
  color: #01B797;
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  border: 4px solid #01B797;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
export const GreenButton = styled.button`
  display: block;
  width: 198px;
  height: 40px;
  margin-top: 2px;
  background-color: #90e06a;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
export const RedButton = styled.button`
  display: block;
  width: 198px;
  height: 40px;
  margin-top: 2px;
  background-color: #ff4a76;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;


export const ButtonHeader = styled.button`
  background-color: #01b797;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  margin: 10px;

`;
