import * as S from "./style";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import HomeChart from "./components/HomeChart";
import GridCard from "./components/GridCard";
import { React, useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

function Home() {

  const [statistical, setStatistical] = useState([]);
  const [loading, setLoading] = useState(true);
  async function getStatistical() {
    try {
      setLoading(true);
      const res = await api.get("v1/gateway/admin/home/statistical");
      setStatistical(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getStatistical();
  }, []);

  return (
    <MainLayout>
      <S.Title>Bem vindo!</S.Title>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress style={{ color: "#07254f" }} />
        </S.ContainerLoading>
      ) : (
        <>
          <GridCard statistical={statistical} />

          <HomeChart statistical={statistical} />
        </>
      )}
    </MainLayout>
  );
}



export default Home;
