import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const TextConfirmation = styled.span`
  margin: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: ${(props) =>
    props.status === "Aprovado"
      ? "#28c76f"
      : props.status === "Reprovado"
      ? "#E93E3E"
      : props.status === "Estornado"
      ? "#E93E3E"
      : props.status === "Cancelado"
      ? "#333333"
      : "#FAAB36"};
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;
`;

export const fullInput = styled.div`
  padding-right: 15px;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 20px;
`;
export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  margin: 20px 5px;
`;
export const BottomButtons = styled.div`
  display: flex;
`;
export const TextSecondary = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const OutlineButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  margin: 5px 10px;
  background-color: #fff;
  color: #0092d4;
  font-size: 14px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid #0092d4;
  cursor: pointer;
  transition: all 0.3s ease-out;

  p {
    margin: 10px 5px;
  }

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
export const DefaultButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  margin: 5px 10px;
  background-color: #0092d4;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid #0092d4;
  cursor: pointer;
  transition: all 0.3s ease-out;

  p {
    margin: 10px 5px;
  }

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;

export const ContainerSelect = styled.div`
  margin-bottom: 10px;
`;


