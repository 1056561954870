import React, { createContext, useState, useMemo } from "react";
import { initailStatePartner } from "./types";

export const GlobalContext = createContext({});

export default function GlobalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [partnerForm, setPartnerForm] = useState(initailStatePartner)
  const [customerId, setCustomerId] = useState(null)

  const providerValue = useMemo(() => ({
    open,
    setOpen,
    partnerForm,
    setPartnerForm,
    customerId,
    setCustomerId
  }), [
    open,
    setOpen,
    partnerForm,
    setPartnerForm,
    customerId,
    setCustomerId
  ])

  return (
    <GlobalContext.Provider
      value={providerValue}
    >
      {children}
    </GlobalContext.Provider>
  );
}


