import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainLayout from '../../layouts/MainLayout';
import * as S from './style'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress';
import ModalDetails from './components/modal'
import api from '../../services/index'
import Cloud from '../../assets/images/iconCloud.svg'
import * as XLSX from 'xlsx'

export default function ListBuyer() {
  const [data, setData] = useState([])
  const [buyers, setBuyers] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [body, setBody] = useState({
    type: "",
    content: ""
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openModalDetails(buyer) {
    setData(buyer)
    setModalIsOpen(true)
    setAnchorEl(null);
  }

  function HandleExport() {
    setLoading(true)
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(buyers)

    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1')

    XLSX.writeFile(wb, 'Compradores.xlsx')
    setLoading(false)
  }

  async function getBuyers() {
    try {
      setLoading(true)
      const res = await api.post('/v1/gateway/admin/buyer/list', body)
      setBuyers(res.data.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getBuyers()
  }, [])


  return (
    <MainLayout title="Cadastro de compradores">
      <S.Header>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>Selecionar filtro</option>
          <option value="document">CPF/CNPJ</option>
          <option value="name">Nome</option>
          <option value="email">E-mail</option>
          <option value="phone">Telefone</option>
        </S.Select>
        <S.Input placeholder='Busque pelo nome' onChange={(e) => setBody({ ...body, content: e.target.value })} />
        <S.Button disabled={loading} onClick={() => getBuyers()}>Pesquisar</S.Button>
      </S.Header>
      <S.ContainerCSV>
        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.ContainerCSV>
      <TableContainer component={Paper}>
        {loading ?
          (
            <S.ContainerLoading>
              <CircularProgress style={{ color: '#07254f' }} />
            </S.ContainerLoading>
          )
          :
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#B2B2B2' }}>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Cidade/Estado</TableCell>
                <TableCell>Total compras</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyers?.map((buyer) => (
                <TableRow
                  key={buyer.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {buyer.name}
                  </TableCell>
                  <TableCell>{buyer.document}</TableCell>
                  <TableCell>{buyer.email}</TableCell>
                  <TableCell>{buyer.phone}</TableCell>
                  <TableCell>{buyer?.address?.city}</TableCell>
                  <TableCell>R$ {buyer.total_buy}</TableCell>
                  <TableCell onClick={() => openModalDetails(buyer)} >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}

                    >
                      <MenuItem
                      >
                        <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                        Detalhes
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <ModalDetails setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} data={data} />
    </MainLayout>
  );
}