import styled from 'styled-components'

export const Header = styled.header`
  margin: 15px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 27px 0 10px 0;
`

export const Select = styled.select`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  padding: 10px;
  margin-right: 20px;
`
export const Input = styled.input`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  padding: 10px;

  :focus{
    outline: none;
  }
`
export const Button = styled.button`
  display: block;
  width: 159px;
  height: 40px;
  margin-left: 20px;
  margin-top: 2px;
  background-color: #07254f;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonHeader = styled.button`
  background-color: #01b797;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  margin: 10px;

`;

export const ImgCloud = styled.img`
  padding-right: 5px;
`;

export const TimeFilter = styled.div`
  margin-right: 20px;

`

