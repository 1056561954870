import * as S from "./style";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F5F5F5",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.secondary,
  height: "80px",
  display: "flex",
  justifyContent: "start",
  paddingLeft: "30px",
  alignItems: "center",
  "& > h1": {
    fontSize: "30px",
    color: "#000",
  },
  "& > p": {
    fontWeight: "bold",
  },
}));

function GridCard({ statistical }) {

  const value = [
    {
      number: statistical.active_customers,
      text: `Total de lojistas ativos`,
    },
    {
      number: statistical.registration_analysis,
      text: `Cadastros em análise`,
    },
    {
      number: statistical.canceled_registrations,
      text: `Cadastros cancelados`,
    },
    {
      number: statistical.total_transactions,
      text: `Total de transações`,
    },
    {
      number: `${new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(statistical?.total_amount_transaction)}`,
      text: `Total em R$ das transações`,
    },
    {
      number: `${new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(statistical?.total_paid_customers)}`,
      text: `Total em R$ já pago aos lojistas`,
    },
  ];

  return (
    <S.Section>
      <Box sx={{ flexGrow: 4 }}>
        <Grid container spacing={4}>
          {value.map((item) => {
            return (
              <Grid xs={6}>
                <Item>
                  <h1> {item.number}</h1>
                  <S.Pipe />
                  <p> {item.text}</p>
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </S.Section>
  );
}

export default GridCard;
