import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0092D4;
`

export const Wrapper = styled.section`
  width: 943px;
  height: 552px;
  display: flex;
  gap: 28px;
  background-color: #fff;
  border-radius: 20px;
`

export const Content = styled.article`
  margin-top: 23px;

  p {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0 19px 0;
    color: #000;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    color: #040404;
  }
`

export const Form = styled.form`
  width: 390px;
  margin-bottom: 172px;
`

export const Input = styled.input`
  margin-bottom: 17px;
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
`

export const Label = styled.label`
  display: block;
  font-size: 15px;
  font-weight: 300;
  color: #040404;
  margin-bottom: 5px;
`

export const Button = styled.button`
  display: block;
  width: 100%;
  height: 38px;
  margin-top: 2px;
  background-color: #01B797;
  color: #FFF;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`