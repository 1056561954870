export const initailStatePartner = [{
    customer_id: "",
    name: "",
    email: "",
    rg: "",
    cpf: "",
    nationality: "",
    birthday: "",
    genre: "",
    marital_status: "",
    phone: "",
    profession: "",
    mother_name: "",
    father_name: "",
    address: {
      zip_code: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
    },
    documents:{
      cnh_rg_rne: [],
      comprovante_residencia : []       
    }            
}]