import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `45%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({ modalIsOpen, setModalIsOpen, data }) {
  const handleClose = () => setModalIsOpen(false);


  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Tarifas e Taxas: {data?.name}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.DivRow>
              <S.Text>Tarifa Anti Fraude: {data?.af_tax}</S.Text>
              <S.Text>Tarifa de Processamento: {data?.process_tax}</S.Text>
              <S.Text>Taxa de saque: {data?.withdraw_tax} </S.Text>
            </S.DivRow>
          </S.ContainerTitle>

          <S.ContainerTitle>
            <S.DivRow>
              <S.Text>Taxa de adiantamento: {data?.rav_tax}</S.Text>
            </S.DivRow>
          </S.ContainerTitle>

          <S.DivRowContainer>
            <span>MDR parcelamento à vista: {data.mdr_tax[1]}</span>
            <span>MDR parcelamento 2x: {data?.mdr_tax[2]}</span>
            <span>MDR parcelamento 3x: {data?.mdr_tax[3]}</span>
            <span>MDR parcelamento 4x: {data?.mdr_tax[4]}</span>
            <span>MDR parcelamento 5x: {data?.mdr_tax[5]}</span>
            <span>MDR parcelamento 6x: {data?.mdr_tax[6]}</span>
            <span>MDR parcelamento 7x: {data?.mdr_tax[7]}</span>
            <span>MDR parcelamento 8x: {data?.mdr_tax[8]}</span>
            <span>MDR parcelamento 9x: {data?.mdr_tax[9]}</span>
            <span>MDR parcelamento 10x: {data?.mdr_tax[10]}</span>
            <span>MDR parcelamento 11x: {data?.mdr_tax[11]}</span>
            <span>MDR parcelamento 12x: {data?.mdr_tax[12]}</span>
          </S.DivRowContainer>
        </Typography>
      </Box>
    </Modal>
  );
}
