import { useState, useContext, useEffect } from "react";

import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import { TextField } from "@mui/material";
import api from "../../../../services/index";
import FileUpload from "react-material-file-upload";
import { GlobalContext } from "../../../../context/GlobalContext";
import { getBase64 } from "../../../../utils/getBase64";
import { phoneMask, cpfMask, dateMask } from "../../../../utils/masks";
import * as S from "./style";

function PartnerFormComponent({ index, position }) {
  const { partnerForm, setPartnerForm, customerId } = useContext(GlobalContext)
  
  const [loading, setLoading] = useState(false);
  const [cnh, setCnh] = useState()
  const [comprovante, setComprovante] = useState()

  useEffect(() => {
    partnerForm[index] = {
      ...partnerForm[index],
      customer_id: customerId,
      partner_id: position
    }
  }, [])

  function handleChange(e, type) {
    const { name, value } = e.target
    
    if(type === 'address') {
      setPartnerForm(prevState => {
        const newState = prevState.map(obj => {
          if (obj.partner_id === position) {
            return {
              ...obj,
              address: {
                ...obj.address,
                [name]: value
              }
            };
          }
  
          return obj;
        });
  
        return newState;
      });

      return  
    }

    setPartnerForm(prevState => {
      const newState = prevState.map(obj => {
        if (obj.partner_id === position) {
          return {...obj, [name]: value};
        }

        return obj;
      });

      return newState;
    });
  }

  async function handleDocument(file, name) {
    const fileBase64 = await getBase64(file[0]) 

    setPartnerForm(prevState => {
      const newState = prevState.map(obj => {
        if (obj.partner_id === position) {
          return {
            ...obj,
            documents: {
              ...obj.documents,
              [name]: fileBase64
            }
          };
        }

        return obj;
      });

      return newState;
    });
  }

  console.log(partnerForm)

  return (
    <>
      <S.BigTitle>Sócio {position}</S.BigTitle>
      <S.Title>Dados do Cliente</S.Title>

      <S.Form>
        <S.fullInput>
          <Label>Nome completo</Label>
          <Input
            name="name"
            onChange={handleChange}
            value={partnerForm[index].name}
          />
        </S.fullInput>

        <S.Column>
          <S.divWrapper>
            <Label>Telefone</Label>
            <Input
              name="phone"
              onChange={handleChange}
              value={phoneMask(partnerForm[index].phone)}
            />
          </S.divWrapper>
        </S.Column>

        <S.fullInput>
          <Label>E-mail</Label>
          <Input 
            name="email"
            onChange={handleChange}
            value={partnerForm[index].email}
          />
        </S.fullInput>

        <S.Column>
          <S.divWrapper>
            <Label>Data Nascimento</Label>
            <Input
              name="birthday"
              onChange={handleChange}
              maxLength={10}
              value={dateMask(partnerForm[index].birthday)}
            />
          </S.divWrapper>

          <S.divWrapper>
            <Label>Genero</Label>
            <S.Select
              name="genre"
              onChange={handleChange}
              value={partnerForm[index].genre}
            >
              <option value="" selected>...</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </S.Select>
          </S.divWrapper>

          <S.divWrapper>
            <Label>Estado Civil</Label>
            <S.Select
              name="marital_status"
              onChange={handleChange}
              value={partnerForm[index].marital_status}
            >
              <option value="" selected>...</option>
              <option value="solteiro">Solteiro(a)</option>
              <option value="casado">Casado(a)</option>
              <option value="viuvo">Viúvo(a)</option>
              <option value="divorciado">Divorciado(a)</option>
            </S.Select>
          </S.divWrapper>
        </S.Column>
        <S.Column>
          <S.divWrapper>
            <Label>RG</Label>
            <Input 
              name="rg"
              onChange={handleChange}
              value={partnerForm[index].rg}
            />
          </S.divWrapper>
          <S.divWrapper>
            <Label>CPF</Label>
            <Input 
              name="cpf"
              maxLength={12}
              onChange={handleChange}
              value={cpfMask(partnerForm[index].cpf)}
            />
          </S.divWrapper>
          <S.divWrapper>
            <Label>Nacionalidade</Label>
            <Input
              name="nationality"
              onChange={handleChange}
              value={partnerForm[index].nationality}
            />
          </S.divWrapper>
        </S.Column>
        <S.fullInput>
          <Label>Profissão</Label>
          <Input
            name="profession"
            onChange={handleChange}
            value={partnerForm[index].profession}
          />
        </S.fullInput>
        <S.Column>
          <S.divWrapper>
            <Label>CEP</Label>
            <Input
              name="zip_code"
              onChange={e => handleChange(e, 'address')}
              value={partnerForm[index].address.zip_code}
            />
          </S.divWrapper>

          <S.divWrapper>
            <Label>Cidade</Label>
            <Input
              name="city"
              onChange={e => handleChange(e, 'address')}
              value={partnerForm[index].address.city}
            />
          </S.divWrapper>

          <S.divWrapper>
            <Label>Estado</Label>
            <Input
              name="state"
              onChange={e => handleChange(e, 'address')}
              value={partnerForm[index].address.state}
            />
          </S.divWrapper>
        </S.Column>

        <S.fullInput>
          <Label>Endereço</Label>
          <Input
            name="street"
            onChange={e => handleChange(e, 'address')}
            value={partnerForm[index].address.street}
          />
        </S.fullInput>

        <S.divWrapper>
          <Label>Número</Label>
          <Input
            name="number"
            type="number"
            onChange={e => handleChange(e, 'address')}
            value={partnerForm[index].address.number}
          />
        </S.divWrapper>

        <S.fullInput>
          <Label>Bairro</Label>
          <Input
            name="neighborhood"
            onChange={e => handleChange(e, 'address')}
            value={partnerForm[index].address.neighborhood}
          />
        </S.fullInput>
        <S.fullInput>
          <Label>Complemento</Label>
          <Input
            name="complement"
            onChange={e => handleChange(e, 'address')}
            value={partnerForm[index].address.complement}
          />
        </S.fullInput>
        <S.fullInput>
          <Label>Nome do Pai</Label>
          <Input
            name="father_name"
            onChange={handleChange}
            value={partnerForm[index].father_name}
          />
        </S.fullInput>
        <S.fullInput>
          <Label>Nome da Mãe</Label>
          <Input
            name="mother_name"
            onChange={handleChange}
            value={partnerForm[index].mother_name}
          />
        </S.fullInput>
        <S.uploadWrapper>
            <Label>Documento com foto</Label>
            <FileUpload
              value={cnh}
              onChange={e => {
                handleDocument(e, 'cnh_rg_rne')
                setCnh(e)
              }}
            />
          </S.uploadWrapper>
          <S.uploadWrapper>
            <Label>Comprovante de Endereço</Label>
            <FileUpload
              value={comprovante}
              onChange={e => {
                handleDocument(e, 'comprovante_residencia')
                setComprovante(e)
              }}
            />
          </S.uploadWrapper>
      </S.Form>
    </>
  );
}

export default PartnerFormComponent;
