import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 7px;
  top: 6px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const ContainerTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const OutlineButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px;
  height: 50px;
  margin-top: 2px;
  background-color: #fff;
  color: #0092d4;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid #0092d4;
  cursor: pointer;
  transition: all 0.3s ease-out;

  p {
    margin: 10px 5px;
  }

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
