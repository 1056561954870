import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import * as S from "./style";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `45%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({
  modalIsOpen,
  setModalIsOpen,
  data,
  setModalLastConfirmationIsOpen,
  status,
  setReason,
}) {
  const handleClose = () => setModalIsOpen(false);
  const openModalLastConfirmation = () => setModalLastConfirmationIsOpen(true);

  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Dados da solicitação do saque:
        </Typography>
        <S.ContainerTitle>
          <S.Text>Lojista solicitante: {data?.customer?.name}</S.Text>
        </S.ContainerTitle>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Dados bancários:
        </Typography>
        <S.ContainerTitle>
          <S.Text>
            Agência: {data?.customer.bank.agency} Conta:
            {data?.customer.bank.account} Proprietário:
            {data?.customer.bank.owner_account} Documento:
            {data?.customer.bank.owner_document}
          </S.Text>
        </S.ContainerTitle>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Valor total:
        </Typography>
        <S.ContainerTitle>
          <S.Text>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(data?.value)}
          </S.Text>
        </S.ContainerTitle>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Data e hora da solicitação:
        </Typography>
        <S.ContainerTitle>
          <S.Text>{data?.date}</S.Text>
        </S.ContainerTitle>

        <Typography id="modal-modal-title" variant="h5" component="h2">
          Atualizar status para:
        </Typography>
        <S.ContainerTitle>
          <S.TextConfirmation status={status}>{status}</S.TextConfirmation>
        </S.ContainerTitle>

        <S.ContainerSelect>
          <S.Select
            onChange={(e) => {
              setReason(e.target.value);
            }}
          >
            <option value="" disabled selected hidden>
              Selecione um dos motivos disponíveis
            </option>
            <option value="CPF do titular diferente do cadastrado">
              CPF do titular diferente do cadastrado
            </option>
            <option value="Dados cadastrais inválidos">
              Dados cadastrais inválidos
            </option>
            <option value="Problemas técnicos identificados">
              Problemas técnicos identificados
            </option>
            <option value="Problemas de processamento">
              Problemas de processamento
            </option>
            <option value="Políticas e regras de uso">
              Políticas e regras de uso
            </option>
            <option value="Critérios administrativos">
              Critérios administrativos
            </option>
            <option value="Outros">Outros</option>
          </S.Select>
        </S.ContainerSelect>

        <S.Footer>
          <S.TitleFooter>
            Você tem certeza que podemos atualizar o status da solicitação?
          </S.TitleFooter>
          <S.ButtonWrap>
            <S.ButtonYes
              onClick={() => {
                handleClose();
                openModalLastConfirmation();
              }}
            >
              SIM
            </S.ButtonYes>
            <S.ButtonNo onClick={handleClose}>NÃO</S.ButtonNo>
          </S.ButtonWrap>
        </S.Footer>
      </Box>
    </Modal>
  );
}
