import * as S from "./style";

import { useNavigate } from "react-router-dom";

import payhowLogo from "../../assets/images/payhow-logo-white.svg";

function MainLayout({ children, title }) {
  const navigate = useNavigate();

  function removeToken() {
    localStorage.removeItem('token')
    navigate('/')
  }

  return (
    <S.Container>
      <S.Navbar>
        <S.Header
          onClick={() => {
            navigate("/home");
          }}
        >
          <img src={payhowLogo} alt="logo" />
          <p>BackOffice</p>
        </S.Header>

        <S.Items>
          {/* Conteudo exclusivo de cada projeto */}
          <p>versão 1.0</p>
        </S.Items>

        <S.GroupItems>
          <S.MenuItems
            onClick={() => {
              navigate("/home");
            }}
          >
            <p>1. Home</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/listagem-lojista");
            }}
          >
            <p>2. Cadastro de lojistas</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/cadastro-comprador");
            }}
          >
            <p>3. Cadastro de compradores</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/historico-de-transacoes");
            }}
          >
            <p>4. Histórico de transações</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/listagem-de-taxas");
            }}
          >
            <p>5. Taxas e Tarifas</p>
          </S.MenuItems>
          <S.MenuItems
            onClick={() => {
              navigate("/extrato-financeiro");
            }}
          >
            <p>6. Extrato financeiro</p>
          </S.MenuItems>
          <S.MenuItems
            onClick={() => {
              navigate("/execucao-pagamentos");
            }}
          >
            <p>7. Execução de pagamentos</p>
          </S.MenuItems>
          <S.MenuItems
            onClick={() => {
              navigate("/solicitacao-saque");
            }}
          >
            <p>8. Solicitação de saque</p>
          </S.MenuItems>
        </S.GroupItems>
      </S.Navbar>

      <S.Content>
        <S.ContentHeader>
          <S.Title>{title}</S.Title>

          <S.Button
            onClick={() => {
              removeToken();
            }}
          >
            Logout
          </S.Button>
        </S.ContentHeader>
        {children}
      </S.Content>
    </S.Container>
  );
}

export default MainLayout;
