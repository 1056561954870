import styled from 'styled-components'

export const Section = styled.div`
  margin: 20px;
`
export const Pipe = styled.div`
  height: 90%;
  margin: 10px;
  border-right: 2px solid #000
`

