import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainLayout from "../../layouts/MainLayout";
import * as S from "./style";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cloud from "../../assets/images/iconCloud.svg";
import AddIcon from "@mui/icons-material/Add";
import ModalDetails from "./components/modal";
import Label from "../../components/Label";
import api from "../../services/index";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

function createData(name, document, email, phone, city, total) {
  return { name, document, email, phone, city, total };
}

export default function ListShop() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shop, setShop] = React.useState();
  const [body, setBody] = React.useState({
    type: "",
    content: "",
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openModalDetails(row) {
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getShop() {
    try {
      setLoading(true);
      const res = await api.post("v1/gateway/admin/customers/list", body);
      setShop(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(shop);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Compradores.xlsx");
    setLoading(false);
  }

  React.useEffect(() => {
    getShop();
  }, []);

  return (
    <MainLayout title="Cadastro de lojistas">
      <S.Header>
        <S.TimeFilter>
          <Label>Data Inicial</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter>
          <Label>Data Final</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="document">CPF/CNPJ</option>
          <option value="name">Nome</option>
          <option value="email">E-mail</option>
          <option value="phone">Telefone</option>
        </S.Select>
        <S.Input placeholder="Busque pelo nome" />
        <S.Button disabled={loading} onClick={() => getShop()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <S.ButtonHeader onClick={() => openModalDetails()}>
          <AddIcon></AddIcon>Novo Cadastro
        </S.ButtonHeader>

        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.HeaderButtons>
      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Data e Hora</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Cidade/Estado</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {/* name, document, email, phone, city, total */}
            <TableBody>
              {shop?.map((shop) => (
                <TableRow
                  key={shop.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {shop.date}
                  </TableCell>
                  <TableCell>{shop.customer}</TableCell>
                  <TableCell>{shop.cpf_cnpj}</TableCell>
                  <TableCell>{shop.email}</TableCell>
                  <TableCell>{shop.phone}</TableCell>
                  <TableCell>{shop.city}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        {shop.cpf_cnpj.length >= 15 ? (
                          <Link
                            to={`/cadastro-lojista-pj`}
                            state={{ customer_id: shop.id }}
                          >
                            Editar Cadastro
                          </Link>
                        ) : (
                          <Link
                            to={`/cadastro-lojista-pf`}
                            state={{ customer_id: shop.id }}
                          >
                            Editar Cadastro
                          </Link>
                        )}
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <ModalDetails setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />
    </MainLayout>
  );
}
