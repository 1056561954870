import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #0092d4;
`;

export const TextConfirmation = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: ${(props) =>
    props.status === "Aprovado"
      ? "#28c76f"
      : props.status === "Reprovado"
      ? "#E93E3E"
      : props.status === "Estornado"
      ? "#E93E3E"
      : props.status === "Cancelado"
      ? "#333333"
      : "#FAAB36"};
`;

export const ContainerTitle = styled.div`
  margin-bottom: 10px;
`;

export const ContainerSelect = styled.div`
  margin-bottom: 10px;
`;

export const Select = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 20px;
`;

export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Footer = styled.div`
  margin: 20px 0px;
`;

export const TitleFooter = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const ButtonYes = styled.button`
  background-color: #fff;
  border-color: #0092d4;
  border-radius: 10px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0092d4;
  font-size: 18px;
  margin: 10px;
`;

export const ButtonNo = styled.button`
  background-color: #0092d4;
  border-color: #0092d4;
  border-radius: 10px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  margin: 10px;
`;
