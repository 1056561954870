import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainLayout from "../../layouts/MainLayout";
import * as S from "./style";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Checkbox, IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cloud from "../../assets/images/iconCloud.svg";
import CircularProgress from "@mui/material/CircularProgress";
import ModalDetails from "./components/modalDetails";
import ModalInfo from "./components/modalInfo";
import ModalCheck from "./components/modalCheck";
import Label from "../../components/Label";
import * as XLSX from "xlsx";
import api from "../../services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function createData(date, code, status, financialStatus, value, seller) {
  return { date, code, status, financialStatus, value, seller };
}

const rows = [
  createData(
    "25/12/2022 14:28",
    "#3541665",
    "pendente",
    "pendente",
    "R$ 7.200,00",
    "Joaquim Santos"
  ),
  createData(
    "25/12/2022 14:28",
    "#3541665",
    "pendente",
    "pendente",
    "R$ 7.200,00",
    "Joaquim Santos"
  ),
  createData(
    "25/12/2022 14:28",
    "#3541665",
    "pendente",
    "pendente",
    "R$ 7.200,00",
    "Joaquim Santos"
  ),
  createData(
    "25/12/2022 14:28",
    "#3541665",
    "pendente",
    "pendente",
    "R$ 7.200,00",
    "Joaquim Santos"
  ),
  createData(
    "25/12/2022 14:28",
    "#3541665",
    "pendente",
    "pendente",
    "R$ 7.200,00",
    "Joaquim Santos"
  ),
];

export default function ExecutionPayments() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalIsOpenDetails, setModalIsOpenDetails] = React.useState(false);
  const [modalIsOpenInfo, setModalIsOpenInfo] = React.useState(false);
  const [modalIsOpenCheck, setModalIsOpenCheck] = React.useState(false);
  const [paymenetExecution, setPaymentExecution] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [body, setBody] = React.useState({
    type: "",
    content: "",
    start: "",
    end: "",
  });
  const [selected, setSelected] = React.useState([]);
  const [dataClear, setDataClear] = React.useState([]);

  const MySwal = withReactContent(Swal);
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("items selecionados", selected);

  function openModalDetails(row) {
    setData(row);
    setModalIsOpenDetails(true);
    setAnchorEl(null);
  }

  function openModalInfo(status) {
    if (selected < 1) {
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Selecione pelo menos um item na tabela",
      });
      
    } else {
    setDataClear([]);

    setStatus(status);
    setModalIsOpenInfo(true);
    setAnchorEl(null);

    paymenetExecution.map((item) => {
      if (item.checked === true) {
        setDataClear((oldState) => [...oldState, item]);
      }
    });
  }
  }
  function openModalCheck() {
    setModalIsOpenCheck(true);
    setAnchorEl(null);
  }
  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(paymenetExecution);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "ExecucaodePagamentos.xlsx");
    setLoading(false);
  }

  const handleClickTable = (event, id, name, value) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    setPaymentExecution((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.transaction_id === id) {
          if (obj.checked === false) {
            return { ...obj, checked: true };
          } else {
            return { ...obj, checked: false };
          }
        }
        return obj;
      });
      return newState;
    });

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = paymenetExecution.map((n) => n.transaction_id);
      setSelected(newSelected);

      setPaymentExecution((prevState) => {
        const newState = prevState.map((obj) => {
          if (event.target.checked) {
            return { ...obj, checked: true };
          }
        });
        return newState;
      });
      return;
    }
    setSelected([]);
    setPaymentExecution((prevState) => {
      const newState = prevState.map((obj) => {
        return { ...obj, checked: false };
      });
      return newState;
    });
  };

  async function getPaymentExecution() {
    try {
      setLoading(true);
      const res = await api.post(
        "v1/gateway/admin/payment-execution/list",
        body
      );
      setPaymentExecution([]);
      res.data.data.map((item) => {
        setPaymentExecution((oldState) => [
          ...oldState,
          {
            billing_status: item.billing_status,
            customer: item.customer,
            date: item.date,
            payment_status: item.payment_status,
            transaction_id: item.transaction_id,
            value: item.value,
            checked: false,
          },
        ]);
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function UpdatePaymentExecution() {
    let body = {
      ids: selected,
      status: status,
    };
    try {
      setLoading(true);
      const res = await api.put(
        "/v1/gateway/admin/payment-execution/status",
        body
      );

      setSelected([]);
      setModalIsOpenCheck(false);
      setLoading(false);
      if (res.data.error === false) {
        MySwal.fire({
          icon: "success",
          title: "Status alterado!",
          confirmButtonText: "ok!",
          preConfirm: () => {
            window.location.reload();
          },
        });
      }

      if (res.data.error === `true`) {
        MySwal.fire({
          icon: "error",
          title: "Erro!",
          text: "Tente novamente...",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
 
  const isSelected = (id) => selected.indexOf(id) !== -1;

  React.useEffect(() => {
    getPaymentExecution();
  }, []);

  return (
    <MainLayout title="Execução de pagamentos">
      <S.Header>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, start: e.target.value })}
        >
          <Label>Data Inicial</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, end: e.target.value })}
        >
          <Label>Data Final</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="code">Código da transação</option>
          <option value="statustransaction">Status da transação</option>
          <option value="statusfinance">Status financeiro</option>
          <option value="seller">Lojista</option>
          <option value="CPF">CPF/CNPJ</option>
          <option value="email">Email</option>
        </S.Select>
        <S.Input placeholder="Busque pelo CNPJ" />
        <S.Button disabled={loading} onClick={() => getPaymentExecution()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <S.ButtonHeader
          /* disabled={selected < 1} */
          onClick={() => openModalInfo("Aprovado")}
        >
          <DoneAllIcon></DoneAllIcon>autorizar transações
        </S.ButtonHeader>
        <S.ButtonHeader
          disabled={selected < 1}
          onClick={() => openModalInfo("Reprovado")}
        >
          <PanToolOutlinedIcon fontSize="small"></PanToolOutlinedIcon>reprovar
          transações
        </S.ButtonHeader>
        <S.ButtonHeader
          disabled={selected < 1}
          onClick={() => openModalInfo("Cancelado")}
        >
          <HighlightOffIcon></HighlightOffIcon>cancelar transações
        </S.ButtonHeader>

        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.HeaderButtons>
      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < paymenetExecution.length
                    }
                    checked={
                      paymenetExecution.length > 0 &&
                      selected.length === paymenetExecution.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                <TableCell>Data e Hora</TableCell>
                <TableCell>Código da Transação</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Status Financeiro</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Lojista</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {/* name, document, email, phone, city, total */}
            <TableBody>
              {paymenetExecution.map((paymenetExecution) => {
                const isItemSelected = isSelected(
                  paymenetExecution.transaction_id
                );
                return (
                  <TableRow
                    key={paymenetExecution.transaction_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={(event) =>
                      handleClickTable(
                        event,
                        paymenetExecution.transaction_id,
                        paymenetExecution.customer.name,
                        paymenetExecution.value
                      )
                    }
                    role="checkbox"
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {paymenetExecution.date}
                    </TableCell>
                    <TableCell>{paymenetExecution.transaction_id}</TableCell>
                    <TableCell>
                      <S.Icon status={paymenetExecution.payment_status}>
                        <S.TextIcon status={paymenetExecution.payment_status}>
                          {paymenetExecution.payment_status}
                        </S.TextIcon>
                      </S.Icon>
                    </TableCell>
                    <TableCell>
                      <S.Icon status={paymenetExecution.billing_status}>
                        <S.TextIcon status={paymenetExecution.billing_status}>
                          {paymenetExecution.billing_status}
                        </S.TextIcon>
                      </S.Icon>
                    </TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(paymenetExecution.value)}
                    </TableCell>
                    <TableCell>{paymenetExecution.customer.name}</TableCell>
                    <TableCell
                      onClick={() => openModalDetails(paymenetExecution)}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ padding: 0 }}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem>Detalhes da transação</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <ModalDetails
        setModalIsOpenDetails={setModalIsOpenDetails}
        modalIsOpen={modalIsOpenDetails}
        data={data}
      />
      <ModalInfo
        setModalIsOpenInfo={setModalIsOpenInfo}
        modalIsOpenInfo={modalIsOpenInfo}
        setModalIsOpenCheck={setModalIsOpenCheck}
        status={status}
        selected={selected}
        dataClear={dataClear}
      />
      <ModalCheck
        setModalIsOpenCheck={setModalIsOpenCheck}
        modalIsOpenCheck={modalIsOpenCheck}
        UpdatePaymentExecution={UpdatePaymentExecution}
      />
    </MainLayout>
  );
}
