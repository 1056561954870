import { React, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainLayout from "../../layouts/MainLayout";
import * as S from "./style";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import { CircularProgress, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalDetails from "./components/modal";
import Cloud from "../../assets/images/iconCloud.svg";
import api from "../../services";
import * as XLSX from "xlsx";

export default function ListFees() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [feesTariffs, setFeesTariffs] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openModalDetails(row) {

    setData(row);
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getFeesTariffs() {
    setLoading(true);
    try {
      const res = await api.get("/v1/gateway/admin/fees_tariffs/list");
      setFeesTariffs(res.data.credit_card);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFeesTariffs();
  }, []);

  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(feesTariffs);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Tarifas_e_Taxas.xlsx");
    setLoading(false);
  }

  return (
    <MainLayout title="Taxas e tarifas">
      <S.Header>
        <S.ButtonCloud
          onClick={() => {
            HandleExport();
          }}
        >
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonCloud>
      </S.Header>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Nome da Tabela </TableCell>
                <TableCell>Taxa de Antifraude</TableCell>
                <TableCell>Taxa de Processamento </TableCell>
                <TableCell>Taxa e Saque</TableCell>
                <TableCell>Taxa de adiantamento</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {feesTariffs.map((row) => (
                <TableRow
                  key={row?.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell>{row?.af_tax}</TableCell>
                  <TableCell>{row?.process_tax}</TableCell>
                  <TableCell>{row?.withdraw_tax}</TableCell>
                  <TableCell>{row?.rav_tax}</TableCell>
                  <TableCell onClick={() => openModalDetails(row)}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                        Detalhes
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {modalIsOpen ? (
        <ModalDetails
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      ) : (
        ``
      )}
    </MainLayout>
  );
}
