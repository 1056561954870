import * as React from "react";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";
import FileUpload from "react-material-file-upload";
import { useLocation } from "react-router-dom";
import MainLaylout from "../../layouts/MainLayout";
import Input from "../../components/Input";
import Label from "../../components/Label";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import * as S from "./style";
import api from "../../services/index";
import PartnerForm from "./components/PartnerForm";
import { GlobalContext } from "../../context/GlobalContext";
import { initailStatePartner } from "../../context/types";
import { getBase64 } from "../../utils/getBase64";
import { cpfMask, phoneMask, cnpjMask } from "../../utils/masks";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Tab = styled(TabUnstyled)`
  color: #0092d4;
  font-size: 1rem;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    background-color: #f4f4f4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)(() => `margin-bottom: 41px;`);

const initialFormStateOneStep = {
  situation: "",
  financial_situation: "",
  name: " ",
  company_name: "",
  email: "",
  cnpj: "",
  client_type: "PJ",
  phone: "",
  whatsapp: "",
};

const initialFormStateBankInfo = {
  customer_id: "",
  bank_id: 1,
  agency: "",
  account: "",
  owner_account: "",
  owner_document: "",
};

const disabledTab = {
  color: '#c3c2c2',
  cursor: 'unset'
}

function RegisterShopJudicial() {
  const { 
    partnerForm,
    setPartnerForm,
    customerId,
    setCustomerId
  } = React.useContext(GlobalContext)
  const MySwal = withReactContent(Swal)
  const { state, pathname } = useLocation()

  const [fileContract, setFileContract] = React.useState([]);
  const [fileAddress, setFileAddress] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(initialFormStateOneStep);
  const [adress, setAdress] = React.useState([]);
  const [count, setCounter] = React.useState(2);
  const [partners, setPartners] = React.useState([1]);
  const [formBankInfo, setFormBankInfo] = React.useState(
    initialFormStateBankInfo
  );

  React.useEffect(() => {
    if (state) {
      console.log(state)
      getCustomerInfo()
      setCustomerId(state.customer_id)
    }
  }, [])

  function setInput(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setForm((form) => ({ ...form, ...newValue }));
    };
  }

  function setInputBankInfo(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setFormBankInfo((formBankInfo) => ({
        ...formBankInfo,
        ...newValue,
      }));
    };
  }

  function addNewPartner() {
    setCounter(count + 1);
    partners.push(count);
    setPartnerForm(state => [...state, initailStatePartner[0]])
  }

  function RemoveNewPartner() {
    partners.pop();
    partnerForm.pop()
    setCounter(count - 1);
  }

  async function getCustomerInfo() {
    const formType = pathname.split('-')[2]

    try {
      setLoading(true);
      const res = await api.post("/v1/gateway/admin/customers/list/detail", {
        customer_id: state.customer_id,
      });

      const data = formType === 'pj'
        ? res.data?.modelo_detalhe_pj?.customer
        : res.data?.modelo_detalhe_pf?.customer
      console.log(data);

      setForm({
        situation: data.situation,
        financial_situation: data.financial_situation,
        name: data.name,
        company_name: data.company_name,
        email: data.email,
        cnpj: data.cnpj,
        client_type: data.client_type,
        phone: data.phone,
        whatsapp: data.whatsapp,
      });
      setAdress({
        id: data.address.id,
        zip_code: data.address.zip_code,
        state: data.address.state,
        city: data.address.city,
        street: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        complement: data.address.complement,
      });
      setPartnerForm(data.partners)
      setFormBankInfo({
        id: data.bank.id,
        bank_id: data.bank.bank_id,
        agency: data.bank.agency,
        account: data.bank.account,
        owner_account: data.bank.owner_account,
        owner_document: data.bank.owner_document,
      });
      // setFileAddress(data.documents.comp_endereco_empresa)
      // setFileContract(data.documents.contrato_social)
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  // pegar os documentos como base64 e tranformar em file

  async function registerCustomers() {
    const body = {
      situation: form.situation,
      financial_situation: form.financial_situation,
      name: form.name,
      company_name: form.company_name,
      email: form.email,
      cnpj: form.cnpj,
      client_type: "PJ",
      phone: form.phone,
      whatsapp: form.whatsapp,
      address: {
        zip_code: adress.zip_code,
        state: adress.state,
        city: adress.city,
        street: adress.street,
        number: adress.number,
        neighborhood: adress.neighborhood,
        complement: adress.complement,
      },
    };
    const requestType = state ? 'update' : 'register'

    const hasEmptyBody = Object.values(body).some(item => item === '')
    const hasEmptyBodyAddress = Object.values(body.address).some(item => item === undefined)

    if(hasEmptyBody || hasEmptyBodyAddress) {
      MySwal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        text: 'Preencha todos os campos!',
        customClass: {
          confirmButton: 'btn btn-error'
        }
      })
      return
    }

    try {
      setLoading(true);
      const res = requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/update`, body)
        : await api.post(`v1/gateway/admin/customers/register`, body)
      
      setCustomerId(res.data.customer_id)
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados do cliente cadastrado!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerPartner() {
    let hasEmptyPartnerForm = null
    let hasEmptyPartnerAddressForm = null
    const requestType = state ? 'update' : 'register'

    partnerForm.forEach(partner => {  
      hasEmptyPartnerForm =  Object.values(partner).some(item => item === "" || item === undefined)
      hasEmptyPartnerAddressForm = Object.values(partner.address).some(item => item === "" || item === undefined)
    })

    if (hasEmptyPartnerAddressForm || hasEmptyPartnerForm) {
      MySwal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        text: 'Preencha todos os campos!',
        customClass: {
          confirmButton: 'btn btn-error'
        }
      })
      return
    }
    
    try {  
      setLoading(true);
     
      requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/partners/update`, partnerForm)
        : await api.post(`v1/gateway/admin/customers/partners/register`, partnerForm)

      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados do sócio cadastrado!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerBank() {
    const hasEmptyBankInfo = Object.values(formBankInfo).some(item => item === '')
    const requestType = state ? 'update' : 'register'
  
    if (hasEmptyBankInfo) {
      MySwal.fire({
        icon: 'error',
        title: 'algo deu errado',
        text: 'Preencha todos os campos!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      return
    }

    const body = { ...formBankInfo, customer_id: customerId}

    try {
      setLoading(true);
      
      requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/bank/update`, body)
        : await api.post(`v1/gateway/admin/customers/bank/register`, body)

      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados bancarios cadastrados!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerDocument() {
    const requestType = state ? 'update' : 'register'
    try {
      setLoading(true);
      let contrato_social = await getBase64(fileAddress[0]);
      let comp_endereco_empresa = await getBase64(fileAddress[0]);

      const body = {
        customer_id: customerId,
        contrato_social: contrato_social,
        comp_endereco_empresa: comp_endereco_empresa,
      };
      
      requestType === 'update'
        ? await api.put(`v1/gateway/admin/customers/document/update`, body)
        : await api.post(`v1/gateway/admin/customers/document/register`, body)
      
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Documentos cadastrados!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <MainLaylout title={"Cadastro de Lojistas"}>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Dados do cliente</Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Informações societária
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Dados bancários
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Documentos enviados
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <S.Title>Dados Pessoais</S.Title>

          <S.Form>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="SituacaoCadastral">Situação Cadastral</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, situation: e.target.value })
                  }
                  value={form.situation}
                >
                  <option value="" selected>...</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </S.Select>
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="SituacaoFinanceira">Situação Financeira</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, financial_situation: e.target.value })
                  }
                  value={form.financial_situation}
                >
                  <option value="" selected>...</option>
                  <option value="habilitado">Habilitado</option>
                  <option value="bloqueado">Bloqueado</option>
                </S.Select>
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="RazaoSocial">Razão Social</Label>
              <Input
                name="name"
                onChange={setInput("name")}
                value={form.name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Nome Fantasia">Nome Fantasia</Label>
              <Input
                name="company_name"
                onChange={setInput("company_name")}
                value={form.company_name}
              />
            </S.fullInput>
            <S.divWrapper>
              <Label htmlFor="CNPJ">CNPJ</Label>
              <Input
                name="CNPJ"
                maxLength={18}
                onChange={setInput("cnpj")}
                value={cnpjMask(form.cnpj)}
              />
            </S.divWrapper>
            <S.fullInput>
              <Label htmlFor="E-mail">E-mail</Label>
              <Input
                name="email"
                onChange={setInput("email")}
                value={form.email}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="TelefoneContato">Telefone de contato</Label>
                <Input
                  name="phone"
                  onChange={setInput("phone")}
                  value={phoneMask(form.phone)}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="TelefoneWhatsapp">Whatsapp de contato</Label>
                <Input
                  name="whatsapp"
                  onChange={setInput("whatsapp")}
                  value={phoneMask(form.whatsapp)}
                />
              </S.divWrapper>
            </S.Column>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="CEP">CEP</Label>
                <Input
                  name="zip_code"
                  onChange={(e) =>
                    setAdress({ ...adress, zip_code: e.target.value })
                  }
                  value={adress.zip_code}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Cidade">Cidade</Label>
                <Input
                  name="city"
                  onChange={(e) =>
                    setAdress({ ...adress, city: e.target.value })
                  }
                  value={adress.city}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="UF">Estado</Label>
                <Input
                  name="state"
                  onChange={(e) =>
                    setAdress({ ...adress, state: e.target.value })
                  }
                  value={adress.state}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Endereco">Endereço</Label>
              <Input
                name="street"
                onChange={(e) =>
                  setAdress({ ...adress, street: e.target.value })
                }
                value={adress.street}
              />
            </S.fullInput>

            <S.divWrapper>
              <Label htmlFor="Numero">Número</Label>
              <Input
                name="number"
                type="number"
                onChange={(e) =>
                  setAdress({ ...adress, number: e.target.value })
                }
                value={adress.number}
              />
            </S.divWrapper>

            <S.fullInput>
              <Label htmlFor="Bairro">Bairro</Label>
              <Input
                name="neighborhood"
                onChange={(e) =>
                  setAdress({ ...adress, neighborhood: e.target.value })
                }
                value={adress.neighborhood}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Complemento">Complemento</Label>
              <Input
                name="complement"
                onChange={(e) =>
                  setAdress({ ...adress, complement: e.target.value })
                }
                value={adress.complement}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerCustomers();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  window.location.href = "/listagem-lojista";
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={1}>
          <S.ButtonWrapper>
            <S.ButtonHeader onClick={() => addNewPartner()}>
              <AddIcon />
              Novo Sócio
            </S.ButtonHeader>
            {partners.length >= 2 ? (
              <S.ButtonHeader onClick={() => RemoveNewPartner()}>
                <RemoveIcon />
                Retirar um sócio
              </S.ButtonHeader>
            ) : (
              ""
            )}
          </S.ButtonWrapper>

          {partnerForm.map((partner, idx) => (
            <PartnerForm
              key={idx}
              index={idx}
              position={idx + 1}
            />
          ))}

          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerPartner();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                window.location.href = "/listagem-lojista"
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
        <TabPanel value={2}>
          <S.Title>Dados Bancários</S.Title>
          <S.Form>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Banco</Label>
              <S.Select
                onChange={setInputBankInfo("bank_id")}
                value={formBankInfo.bank_id}
              >
                <option value="" selected>...</option>
                <option value="1">Bradesco</option>
                <option value="2">Inter</option>
                <option value="3">C6</option>
                <option value="4">Nubank</option>
                <option value="5">Itau</option>
                <option value="6">CAIXA</option>
                <option value="7">Sicredi</option>
              </S.Select>
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Agência">Agência</Label>
                <Input
                  name="agency"
                  onChange={setInputBankInfo("agency")}
                  value={formBankInfo.agency}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Conta Corrente">Conta Corrente</Label>
                <Input
                  name="account"
                  onChange={setInputBankInfo("account")}
                  value={formBankInfo.account}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="CNPJ">CNPJ</Label>
                <Input
                  name="owner_document"
                  maxLength={18}
                  onChange={setInputBankInfo("owner_document")}
                  value={cnpjMask(formBankInfo.owner_document)}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Nome do titular">Nome do titular</Label>
              <Input
                name="owner_account"
                onChange={setInputBankInfo("owner_account")}
                value={formBankInfo.owner_account}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerBank();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  window.location.href = "/listagem-lojista"
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>
        <TabPanel value={3}>
          <S.uploadWrapper>
            <Label htmlFor="CCS">Cópia Contrato Social</Label>
            <FileUpload value={fileContract} onChange={setFileContract} />
          </S.uploadWrapper>
          <S.uploadWrapper>
            <Label htmlFor="ComprovanteEndereco">
              Comprovante de Endereço da Empresa
            </Label>
            <FileUpload value={fileAddress} onChange={setFileAddress} />
          </S.uploadWrapper>
          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerDocument();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                window.location.href = "/listagem-lojista"
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
      </TabsUnstyled>
    </MainLaylout>
  );
}

export default RegisterShopJudicial;
