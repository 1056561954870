import * as S from "./style";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function HomeChart({ statistical }) {
  const data = [
    {
      name: "Semana 1",
      uv: 4000,
      Transações: statistical?.transaction_flow_week[1],
      amt: 2400,
    },
    {
      name: "Semana 2",
      uv: 3000,
      Transações: statistical?.transaction_flow_week[2],
      amt: 2210,
    },
    {
      name: "Semana 3",
      uv: 2000,
      Transações: statistical?.transaction_flow_week[3],
      amt: 2290,
    },
    {
      name: "Semana 4",
      uv: 2780,
      Transações: statistical?.transaction_flow_week[4],
      amt: 2000,
    },
    {
      name: "Semana 5",
      uv: 1890,
      Transações: statistical?.transaction_flow_week[5],
      amt: 2181,
    },
    {
      name: "Semana 6",
      uv: 1890,
      Transações: statistical?.transaction_flow_week[6],
      amt: 2181,
    },
  ];

  return (
    <S.Items>
      <p> Fluxo de cadastros por dia </p>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />

          <Line
            type="linear"
            dataKey="Transações"
            stroke="#FF6485"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </S.Items>
  );
}

export default HomeChart;
