import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const TextStatus = styled.span`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: ${(props) =>
    props.status === "Aprovado"
      ? "#28c76f"
      : props.status === "Reprovado"
      ? "#E93E3E"
      : props.status === "Estornado"
      ? "#E93E3E"
      : props.status === "Cancelado"
      ? "#333333"
      : "#FAAB36"}; ;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;
`;

export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
