import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `70%`,
  height: `70%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
  overflowY: `auto`
};

const rows = [
  createData("Lucas de Moraes", "R$ 2.500,00"),
  createData("Lucas de Moraes", "R$ 2.500,00"),
  createData("Lucas de Moraes", "R$ 2.500,00"),
];

function createData(name, value) {
  return { name, value };
}

export default function ModalInfo({
  modalIsOpenInfo,
  setModalIsOpenInfo,
  setModalIsOpenCheck,
  status,
  selected,
  dataClear
}) {
  const handleClose = () => setModalIsOpenInfo(false);

let totalValue = 0;

for (let index = 0; index < dataClear.length; index++) {
  totalValue += dataClear[index].value;
}

 
  function openModalCheck() {
    setModalIsOpenCheck(true);
    setModalIsOpenInfo(false);
  }

  let listCustomers = selected.length;

  return (
    <Modal
      open={modalIsOpenInfo}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Esta operação impacta nos seguintes resultados:
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>Total de transações selecionadas: {selected.length}</S.Text>
          </S.ContainerTitle>
          <S.ContainerTitle>
            <S.Text>Valor total: {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(totalValue)}</S.Text>
          </S.ContainerTitle>
          <S.ContainerTitle>
            <S.Text>
              Atualizar status para:{" "}
              <S.TextConfirmation status={status}>{status}</S.TextConfirmation>{" "}
            </S.Text>
          </S.ContainerTitle>
          {status !== "Aprovado" ? (
            <S.ContainerTitle>
            Motivo: 
            <S.fullInput>
              <S.ContainerSelect>
              <S.Select>
                    <option value="">
                      Selecione um dos motivos disponíveis
                    </option>
                    <option value="CPF do titular diferente do cadastrado">CPF do titular diferente do cadastrado</option>
                    <option value="Dados cadastrais inválidos">Dados cadastrais inválidos</option>
                    <option value="Problemas técnicos identificados">Problemas técnicos identificados</option>
                    <option value="Problemas de processamento">Problemas de processamento</option>
                    <option value="Políticas e regras de uso">Políticas e regras de uso</option>
                    <option value="Critérios administrativos">Critérios administrativos</option>
                    <option value="Outros">Outros</option>
                  </S.Select>
              </S.ContainerSelect>
            </S.fullInput>
          </S.ContainerTitle>
          ) : ("")}
          <S.ContainerTitle>
            <S.TextSecondary>Lojistas Impactados:</S.TextSecondary>
          </S.ContainerTitle>
          <S.Text></S.Text>
          <S.Container>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
                <TableRow>
                  <TableCell>Lojista</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataClear.map((row) => (
                  <TableRow
                    key={row.customer.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.customer.name}
                    </TableCell>
                    <TableCell>{new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(row.value)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </S.Container>
          <S.BottomInfo>
            <S.TextSecondary>
              Tem certeza que deseja atualizar o sistema?
            </S.TextSecondary>
            <S.BottomButtons>
              <S.OutlineButton onClick={openModalCheck}>
                <p>SIM</p>
              </S.OutlineButton>
              <S.DefaultButton onClick={handleClose}>
                <p>NÃO</p>
              </S.DefaultButton>
            </S.BottomButtons>
          </S.BottomInfo>
        </Typography>
      </Box>
    </Modal>
  );
}
